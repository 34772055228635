<template>
  <div class="alert-modal-bg">
    <div class="alert-modal-content">
      <font-awesome-icon
        icon="fa-solid fa-circle-xmark"
        class="alert-modal-icon"
      />
      <h1 class="alert-modal-title">
        {{ $t("round_alert_required_data_title") }}
      </h1>
      <h2 class="alert-modal-sub-title">
        {{ $t("round_alert_required_data_sub_title") }}
      </h2>
      <ul class="alert-modal-list">
        <li>{{ $tc("company", 1) }}</li>
        <li>{{ $t("vehicle_title") }}</li>
        <li>{{ $t("manager_company") }}</li>
      </ul>
      <div class="alert-modal-button-close">
        <NewDxaButton
          :outline="true"
          :title="$t('close')"
          @btnFunction="handleClick"
        />
      </div>
    </div>
    <div class="alert-modal-icon-close-box">
      <font-awesome-icon
        @click="handleClick"
        icon="fa-solid fa-xmark"
        class="alert-modal-icon-close"
      />
    </div>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";

export default {
  name: "AlertModalRequiredData",
  components: {
    NewDxaButton,
  },
  methods: {
    handleClick() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.alert-modal-bg {
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 54px 20px 20px 20px;
  max-width: 564px;
  max-height: 374px;
  position: relative;
}

.alert-modal-content {
  display: grid;
  place-items: center;
}

.alert-modal-icon {
  color: #ff0000;
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

.alert-modal-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
  text-align: center;
}

.alert-modal-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.alert-modal-list > li {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);

  display: flex;
  align-items: center;
  gap: 8px;
}

.alert-modal-list li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: var(--dark);
  border-radius: 50%;
}

.alert-modal-button-close {
  width: 95px;
  height: 54px;
  margin-top: 20px;
}

.alert-modal-icon-close-box {
  position: absolute;
  top: 24px;
  right: 24px;
}

.alert-modal-icon-close {
  width: 16px;
  height: 16px;
  color: #afafaf;
  cursor: pointer;
}
</style>
