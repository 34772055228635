<template>
  <div class="commitment-content">
    <div class="commitment-header">
      <h1 class="commitment-header-title">
        {{ $t("commitment_document_title") }}
      </h1>
      <p class="commitment-header-subtitle">
        {{ $t("commitment_document_sub_title") }}
      </p>
    </div>
    <v-form class="commitment-form-container">
      <div class="commitment-form-terms-br">
        <h2 class="commitment-form-title">
          {{ $t("term_title_language_pt") }}
        </h2>
        <div>
          <span class="commitment-form-label commitment-form-label-title">{{
            $t(round.preDeal? "letter_of_intent": "commitment_term_key", { language: "pt" })
          }}</span>
          <span class="commitment-form-label commitment-form-label-sub-title">{{
            $t("insert_document_link")
          }}</span>
          <v-text-field
            data-test="Round:Commitment:CommitmentTerm"
            @change="(e) => completeCommitmentTermUrl(e, 'pt')"
            v-model="commitmentTermUrlPt"
            color="primary"
            dense
            class="commitment-form-input-text"
            outlined
          >
          </v-text-field>
        </div>
        <div>
          <span class="commitment-form-label commitment-form-label-title">{{
            $t("proxy_key", { language: "pt" })
          }}</span>
          <span class="commitment-form-label commitment-form-label-sub-title">{{
            $t("insert_document_link")
          }}</span>
          <v-text-field
            data-test="Round:Commitment:ProxyUrl"
            @change="(e) => completeInvestmentProxyUrl(e, 'pt')"
            v-model="investmentProxyUrlPt"
            class="commitment-form-input-text"
            color="primary"
            dense
            outlined
            :disabled="round.preDeal"
          >
          </v-text-field>
        </div>
      </div>
      <!-- documentos em en -->
      <div class="commitment-form-en">
        <h2 class="commitment-form-title">
          {{ $t("term_title_language_en") }}
        </h2>
        <div>
          <span class="commitment-form-label commitment-form-label-title">{{
            $t(round.preDeal? "letter_of_intent": "commitment_term_key", { language: "en" })
          }}</span>
          <span class="commitment-form-label commitment-form-label-sub-title">{{
            $t("insert_document_link")
          }}</span>
          <v-text-field
            data-test="Round:Commitment:CommitmentTermEnglish"
            @change="(e) => completeCommitmentTermUrl(e, 'en')"
            v-model="commitmentTermUrlEn"
            color="primary"
            dense
            class="commitment-form-input-text"
            outlined
          >
          </v-text-field>
        </div>
        <div>
          <span class="commitment-form-label commitment-form-label-title">{{
            $t("proxy_key", { language: "en" })
          }}</span>
          <span class="commitment-form-label commitment-form-label-sub-title">{{
            $t("insert_document_link")
          }}</span>
          <v-text-field
            data-test="Round:Commitment:ProxyUrlEnglish"
            @change="(e) => completeInvestmentProxyUrl(e, 'en')"
            v-model="investmentProxyUrlEn"
            class="commitment-form-input-text"
            color="primary"
            dense
            outlined
            :disabled="round.preDeal"
          >
          </v-text-field>
        </div>
      </div>
    </v-form>
    <ul v-if="partnerConditionInvestmentRounds.length > 0">
      <li v-for="(item, i) in partnerConditionInvestmentRounds" :key="i">
        <h3 class="commitment-partner-condition-title">
          {{
            `${$tc("specials_condition_partner", 1)} ${getNamePartnerSelected(
              item.partnerB2bId
            )}`
          }}
        </h3>
        <h3 class="commitment-form-title">
          {{ $t("term_title_language_pt") }}
        </h3>
        <p class="commitment-partner-condition-text">
          {{ $t("commitment_term_key", { language: "pt" })
          }}<span class="commitment-partner-condition-text-url"
            >{{ JSON.parse(item.partnerCommitmentTermUrl).pt }}
            <font-awesome-icon
              icon="fa-solid fa-pencil"
              @click="openEditDialog(item)"
              data-test="Round:Commitment:ButtonToEditPartnerCommitmentTermUrl"
            />
          </span>
        </p>
        <p class="commitment-partner-condition-text">
          {{ $t("proxy_key", { language: "pt" }) }}
          <span class="commitment-partner-condition-text-url"
            >{{ JSON.parse(item.partnerInvestmentProxyUrl).pt }}
            <font-awesome-icon
              icon="fa-solid fa-pencil"
              @click="openEditDialog(item)"
              data-test="Round:Commitment:ButtonToEditPartnerInvestmentProxyUrl"
            />
          </span>
        </p>
        <h3 class="commitment-form-title">
          {{ $t("term_title_language_en") }}
        </h3>
        <p class="commitment-partner-condition-text">
          {{ $t("commitment_term_key", { language: "en" })
          }}<span class="commitment-partner-condition-text-url"
            >{{ JSON.parse(item.partnerCommitmentTermUrl).en }}
            <font-awesome-icon
              icon="fa-solid fa-pencil"
              @click="openEditDialog(item)"
              data-test="Round:Commitment:ButtonToEditPartnerCommitmentTermUrl"
            />
          </span>
        </p>
        <p class="commitment-partner-condition-text">
          {{ $t("proxy_key", { language: "en" }) }}
          <span class="commitment-partner-condition-text-url"
            >{{ JSON.parse(item.partnerInvestmentProxyUrl).en }}
            <font-awesome-icon
              icon="fa-solid fa-pencil"
              @click="openEditDialog(item)"
              data-test="Round:Commitment:ButtonToEditPartnerInvestmentProxyUrl"
            />
          </span>
        </p>
      </li>
    </ul>
    <div class="commitment-form-button-condition-partner">
      <NewDxaButton
        data-test="Round:Commitment:BtnAddSpecialPartnerCondition"
        @btnFunction="openDialog"
        :outline="true"
        :title="$t('add_specials_condition_partner')"
      />
    </div>
    <v-dialog v-model="dialog" v-if="dialog" scrollable>
      <CommitmentPartnerModal
        :editDialog="editDialog"
        :dataToEdit="dataToEdit"
        @conditionPartner="conditionPartner"
        @closeDialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import CommitmentPartnerModal from "../components/CommitmentPartnerModal";
import ApiService from "@/services/ApiService.js";
import { mapMutations, mapState } from "vuex";
import { round } from "lodash";

export default {
  name: "CommitmentTab",
  components: {
    NewDxaButton,
    CommitmentPartnerModal,
  },
  data() {
    return {
      dialog: false,
      investmentProxyUrlPt: null,
      commitmentTermUrlPt: null,
      partnerConditionInvestmentRounds: [],
      apiService: new ApiService(),
      allPartners: null,
      editDialog: false,
      dataToEdit: null,
      commitmentTermUrlEn: null,
      investmentProxyUrlEn: null,
    };
  },
  async created() {
    await this.getAllPartners();
  },
  computed: {
    ...mapState(["round"]),
  },
  watch: {
    round() {
      let commitmentTermUrl = JSON.parse(this.round.commitmentTermUrl);
      let investmentProxyUrl = JSON.parse(this.round.investmentProxyUrl);

      if (this.round.id && this.$route.params.roundId) {
        this.commitmentTermUrlPt = commitmentTermUrl.pt;
        this.investmentProxyUrlPt = investmentProxyUrl.pt;
        this.commitmentTermUrlEn = commitmentTermUrl.en;
        this.investmentProxyUrlEn = investmentProxyUrl.en;
        this.partnerConditionInvestmentRounds =
          this.round.partnerConditionInvestmentRounds;
      }
    },
    partnerConditionInvestmentRounds() {
      this.setRoundData({
        ...this.round,
        partnerConditionInvestmentRounds: this.partnerConditionInvestmentRounds,
      });
    },
    dialog() {
      // criando watch para toda vez que o modal for fechado garantir que vai limpar as informações sobre edição para prevenir
      // de preencher o modal caso seja feita a ação de adicionar ao invés de editar
      if (!this.dialog) {
        this.editDialog = false;
        this.dataToEdit = null;
      }
    },
  },
  methods: {
    ...mapMutations({
      setRoundData: "SET_ROUND_DATA",
    }),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async getAllPartners() {
      this.loading = true;
      await this.apiService
        .getRequest("partnerb2b")
        .then((result) => {
          this.allPartners = result.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
      this.loading = false;
    },
    getNamePartnerSelected(partnerB2bId) {
      const partnerSelected = this.allPartners.filter((partner) => {
        return partner.id === partnerB2bId;
      });

      const getPartnerName = partnerSelected.map((partner) => {
        return partner.name;
      });

      return getPartnerName[0];
    },
    conditionPartner(payload, edit) {
      this.dialog = false;

      if (edit) {
        // achando o index do item que estamos editando para poder escrever o novo valor exatamente na posição index dele
        let index = this.partnerConditionInvestmentRounds.findIndex(
          (item) => item.partnerB2bId === payload.partnerB2bId
        );

        this.partnerConditionInvestmentRounds[index] = payload;
        this.editDialog = false;
        this.dataToEdit = null;
      } else {
        this.partnerConditionInvestmentRounds = [
          ...this.partnerConditionInvestmentRounds,
          payload,
        ];
      }
    },
    openEditDialog(partnerInformation) {
      this.dialog = true;
      this.editDialog = true;
      this.dataToEdit = partnerInformation;
    },
    completeCommitmentTermUrl(value, language) {
      let parsedValue = this.round.commitmentTermUrl;
      let selectedLanguage = language === "pt";

      if (typeof this.round.commitmentTermUrl === "string") {
        parsedValue = JSON.parse(this.round.commitmentTermUrl);
      }

      if (selectedLanguage) {
        this.setRoundData({
          ...this.round,
          commitmentTermUrl: { ...parsedValue, pt: value },
        });
      } else {
        this.setRoundData({
          ...this.round,
          commitmentTermUrl: { ...parsedValue, en: value },
        });
      }
    },
    completeInvestmentProxyUrl(value, language) {
      let parsedValue = this.round.investmentProxyUrl;
      let selectedLanguage = language === "pt";

      if (typeof this.round.investmentProxyUrl === "string") {
        parsedValue = JSON.parse(this.round.investmentProxyUrl);
      }

      if (selectedLanguage) {
        this.setRoundData({
          ...this.round,
          investmentProxyUrl: { ...parsedValue, pt: value },
        });
      } else {
        this.setRoundData({
          ...this.round,
          investmentProxyUrl: { ...parsedValue, en: value },
        });
      }
    },
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  padding: 0;
}
/* styles general */
.commitment-content {
  padding: 0 20px;
}

/* styles header */
.commitment-header {
  margin-bottom: 30px;
}

.commitment-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: var(--dark);
  margin-bottom: 20px;
}

.commitment-header-subtitle {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

/* styles form */
.commitment-form-label {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
}

.commitment-form-label-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.commitment-form-label-sub-title {
  font-size: 11px;
  margin-bottom: 8px;
}

.commitment-form-input-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: var(--dark);
}

.commitment-form-container {
  width: 50%;
}

.commitment-form-button-condition-partner {
  max-width: 340px;
}

.commitment-partner-condition-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 35px;
}

.commitment-partner-condition-text {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 18px;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
}

.commitment-partner-condition-text-url {
  color: var(--primary);
  text-decoration: underline;
  display: block;
}

.commitment-partner-condition-text-url svg {
  margin-left: 8px;
  cursor: pointer;
  color: currentColor;
}

.commitment-form-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 30px;
}
</style>
