<template>
  <div class="round-tab-container">
    <div class="round-tab-header">
      <div
        class="round-tab-item"
        @click="setActiveItem(item)"
        v-for="item in tabs"
        :key="item.value"
        :data-test="`Round:Tabs:RoundStep:${item.value}`"
      >
        <span class="round-tab-label"> {{ $t(item.label) }} </span>
        <div
          :class="{ 'round-tab-active': activeTab === item.value }"
          class="round-tab-bar"
        />
      </div>
    </div>

    <div class="round-tab-content">
      <RoundData
        :round="round"
        v-show="activeTab === roundTabsEnumData.ROUND_DATA"
      />
      <Closing
        :currentTab="activeTab"
        :isValid="stepIsValid"
        :round="round"
        v-show="activeTab === roundTabsEnumData.CLOSING"
      />
      <Commitment
        :round="round"
        v-show="activeTab === roundTabsEnumData.COMMITMENT"
      />
      <BriefingAndPermissions
        :isValid="stepIsValid"
        :round="round"
        v-show="activeTab === roundTabsEnumData.BRIEFING_AND_PERMISSIONS"
      />
    </div>
  </div>
</template>

<script>
import { roundTabEnum } from "../RoundStatusEnums.js";
import BriefingAndPermissions from "../tabs/BriefingAndPermission";
import Closing from "../tabs/Closing";
import Commitment from "../tabs/Commitment";
import RoundData from "../tabs/RoundData";
import { mapState } from "vuex";

export default {
  name: "Tabs",
  props: {
    round: Object,
  },
  components: {
    BriefingAndPermissions,
    Closing,
    Commitment,
    RoundData,
  },
  computed: {
    ...mapState(["round"]),
  },
  methods: {
    setActiveItem(item) {
      this.activeTab = item.value;
      this.verifyStepValidation(item.value);
    },
    verifyStepValidation() {
      if (this.round.id) {
        this.stepIsValid = true;
      }
    },
  },
  data: () => ({
    roundTabsEnumData: roundTabEnum,
    activeTab: roundTabEnum.ROUND_DATA,
    tabs: [
      {
        value: roundTabEnum.ROUND_DATA,
        label: "round_data",
      },
      { value: roundTabEnum.COMMITMENT, label: "commitment" },
      { value: roundTabEnum.CLOSING, label: "closing" },
      {
        value: roundTabEnum.BRIEFING_AND_PERMISSIONS,
        label: "briefing_and_permissions",
      },
    ],
    stepIsValid: false,
  }),
};
</script>

<style scoped>
.round-tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.round-tab-header {
  display: flex;
  flex-direction: row;
}

.round-tab-item {
  cursor: pointer;
  padding: 20px 2px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round-tab-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-tab-bar {
  background: #e9e9e9;
  height: 8px;
  margin-top: 8px;
  width: 100%;
}

.round-tab-active {
  background: var(--primary);
}

.round-tab-content {
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
