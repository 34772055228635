<template>
  <div class="round-closing-container">
    <span class="round-closing-title">{{ $t("round_closing_documents") }}</span>
    <div class="round-closing-valid" v-if="isValid">
      <span class="round-closing-subtitle">
        {{ $t("round_closing_title") }}
      </span>
      <div
        class="round-closing-documents"
        v-for="(vehicle, key) in round.investmentVehicleInvestmentRounds"
        :key="key"
      >
        <div
          v-show="!loadingList[key]"
          :data-test="`Round:Closing:Vehicle:${vehicleNames[key]}`"
        >
          <div class="vehicle-document-title-container">
            <span class="round-closing-title">
              {{ $t("round_closing_document_title", {}) }}
            </span>
            <span
              class="vehicle-name"
              @click="
                () =>
                  $router.push(`/vehicle/edit/${vehicle.investmentVehicleId}`)
              "
              >{{ vehicleNames[key] }}
            </span>
            <span class="vehicle-nationality">
              <font-awesome-icon
                class="icon-vehicle-nationality"
                icon="fa-solid fa-database"
              />
              {{ getVehicleNationality(vehicle.vehicleNationality) }}</span
            >
          </div>

          <div class="round-closing-data-container">
            <!-- input de ação - sempre vai mostrar independente do tipo de closing -->
            <div class="round-closing-input-container">
              <span class="round-closing-input-title"
                >{{ $t("round_closing_stock_type") }}
              </span>
              <span class="round-closing-input-subtitle">
                {{ $t("round_closing_input_select") }}
              </span>
              <v-autocomplete
                :data-test="`Round:RoundClosing:SelectStockType:${vehicleNames[key]}`"
                :items="stockTypes[key]"
                item-text="stockTypeName"
                item-value="stockTypeId"
                color="primary"
                dense
                class="rounds-filters-auto-complete"
                :label="$t('select')"
                outlined
                v-model="vehicle.stockTypeId"
                @change="(e) => selectVehicleStockType(vehicle, e)"
              >
              </v-autocomplete>
            </div>

            <!-- cenário de closing SA -->
            <div
              v-if="
                vehicle.closingType === vehicleClosingOptionsEnum.CLOSING_SA
              "
            >
              <div class="round-closing-input-container">
                <span class="round-closing-input-title">
                  {{ $t("round_closing_adhesion_term") }}
                </span>
                <span class="round-closing-input-subtitle">
                  {{ $t("round_closing_clicksign_input") }}
                </span>
                <v-text-field
                  data-test="Round:RoundClosing:AdhesionTermSignatureKey"
                  class="closing-form-input-text"
                  color="primary"
                  dense
                  outlined
                  v-model="vehicle.adhesionTermSignatureKey"
                >
                </v-text-field>
              </div>
              <div class="round-closing-input-container">
                <span class="round-closing-input-title">
                  {{ $t("newsletter_subscription") }}
                </span>
                <span class="round-closing-input-subtitle">
                  {{ $t("round_closing_clicksign_input") }}
                </span>
                <v-text-field
                  data-test="Round:RoundClosing:NewsletterSubscriptionSignatureKey"
                  class="closing-form-input-text"
                  color="primary"
                  dense
                  outlined
                  v-model="vehicle.newsletterSubscriptionSignatureKey"
                >
                </v-text-field>
              </div>
            </div>

            <!-- cenário de closing LLC -->
            <div
              v-if="
                vehicle.closingType === vehicleClosingOptionsEnum.CLOSING_LLC
              "
            >
              <div class="round-closing-input-container">
                <span class="round-closing-input-title">
                  {{ $t("subscription_agreement") }}
                </span>
                <span class="round-closing-input-subtitle">
                  {{ $t("round_closing_clicksign_input") }}
                </span>
                <v-text-field
                  data-test="Round:RoundClosing:SubscriptionAgreementSignatureKey"
                  class="closing-form-input-text"
                  color="primary"
                  dense
                  outlined
                  v-model="vehicle.subscriptionAgreementSignatureKey"
                >
                </v-text-field>
              </div>
            </div>

            <!-- cenário de closing fora da plataforma -->
            <div
              class="no-closing-container"
              v-if="
                vehicle.closingType === vehicleClosingOptionsEnum.NO_CLOSING
              "
            >
              <h2 class="no-closing-title">
                {{ $t("no_formalization_documents") }}
              </h2>
              <span class="no-closing-sub-title">{{
                $t("closing_outside_dxa")
              }}</span>
            </div>
          </div>
        </div>
        <div v-show="loadingList[key]">
          <v-progress-circular
            style="margin-top: 8px"
            indeterminate
            size="24"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <div v-else class="round-closing-invalid-section">
      <span class="round-closing-invalid-text1">{{
        $t("round_closing_invalid_text1")
      }}</span>
      <span class="round-closing-invalid-text2">{{
        $t("round_closing_invalid_text2")
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "@/services/ApiService";
import { roundTabEnum } from "../RoundStatusEnums";
import {
  vehicleNationality,
  vehicleClosingOptionsEnum,
} from "@/shared/enums/VehicleEnum";

export default {
  name: "ClosingTab",
  props: {
    isValid: Boolean,
    currentTab: Number,
  },
  data() {
    return {
      stockTypes: [],
      vehicleNames: [],
      apiService: new ApiService(),
      loadingList: [],
      stockTypeSelected: [],
      vehicleNationality,
      vehicleClosingOptionsEnum,
    };
  },
  methods: {
    getVehicleNationality(nationality) {
      const literal = {
        [this.vehicleNationality.NATIONAL]: this.$t("national"),
        [this.vehicleNationality.INTERNATIONAL]: this.$t("international"),
      };

      return literal[nationality];
    },
    checkVehicleRoundData() {
      this.round.investmentVehicleInvestmentRounds.map(
        async (vehicle, index) => {
          this.loadingList[index] = true;
          await this.apiService
            .getRequest(
              `investmentvehicle/vehiclestockvalue/${vehicle.investmentVehicleId}`
            )
            .then((result) => {
              this.$forceUpdate();
              this.loadingList[index] = false;
              this.vehicleNames[index] = result.content.name;
              this.stockTypes[index] = result.content.vehicleStockTypes;
            })
            .catch(() => {
              this.loadingList[index] = false;
              this.$toast.error(this.$t("an_error_occurred"));
            });
        }
      );
    },

    // ao selecionar uma ação, inclui a ação dentro do veículo na store (vuex)
    selectVehicleStockType(vehicle, stockTypeId) {
      let vehicleIndex =
        this.round.investmentVehicleInvestmentRounds.indexOf(vehicle);
      this.round.investmentVehicleInvestmentRounds[vehicleIndex].stockTypeId =
        stockTypeId;
    },
  },
  watch: {
    currentTab() {
      if (this.currentTab === roundTabEnum.CLOSING && this.isValid) {
        this.checkVehicleRoundData();
      }
    },
    isValid() {
      if (this.isValid) {
        this.checkVehicleRoundData();
      }
    },
  },
  computed: {
    ...mapState(["round"]),
  },
};
</script>

<style scoped>
.round-closing-container {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  gap: 10px;
  width: 50%;
}

.round-closing-valid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.round-closing-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  white-space: nowrap;
}

.round-closing-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 10px;
}

.round-closing-data-container {
  display: flex;
  flex-direction: column;
}

.round-closing-input-container {
  display: flex;
  flex-direction: column;
}

.round-closing-input-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-closing-input-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin: 4px 0;
}

.closing-form-input-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: var(--dark);
}

.round-closing-invalid-section {
  display: flex;
  flex-direction: column;
}

.round-closing-invalid-text1 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-closing-invalid-text2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-closing-documents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vehicle-name {
  display: inline-block;
  color: #197dff;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  display: block;
  white-space: nowrap;
}

.vehicle-nationality {
  font-size: 12px;
  font-family: Source Sans Pro;
  font-weight: 700;
  color: #1e429f;
  border-radius: 6px;
  background-color: #e1effe;
  padding: 0px 10px;
  margin-left: 8px;
  display: inline-block;
  bottom: 4px;
  position: relative;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.icon-vehicle-nationality {
  margin-right: 4px;
}

.no-closing-title {
  color: #535353;
  font-family: Source Sans Pro;
  font-size: 16px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
}

.no-closing-sub-title {
  color: #535353;
  font-family: Source Sans Pro;
  font-size: 10px;
  letter-spacing: 0.15px;
  display: block;
}

.vehicle-document-title-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
