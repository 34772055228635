<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div id="rounds-content">
      <a class="round-header-back-page" @click="$router.push('/round/')">
        <font-awesome-icon
          icon="fa-solid fa-arrow-left"
          class="round-header-back-page-icon"
        />
        <span class="round-header-back-page-text">{{
          $t("round_details_title")
        }}</span>
      </a>
      <div class="rounds-header">
        <div>
          <h1 class="rounds-title">{{ $t("new_round") }}</h1>
          <h2 class="rounds-sub-title">{{ $t("rounds_sub_title") }}</h2>
        </div>
      </div>

      <div class="content">
        <Tabs :round="round" />
      </div>
      <div class="round-control">
        <v-switch
          :disabled="publishRoundSwitchDisabled"
          v-model="publishRoundPipeline"
          @change="publishRoundToPipeline"
          color="primary"
          inset
          data-test="Round:NewRound:SwitchPublishRound"
        >
          <template class="round-switch-label" v-slot:label>
            Rodada disponível no Pipeline
          </template>
        </v-switch>
        <div class="round-save-btn-container">
          <NewDxaButton
            data-test="Round:NewRound:BtnSaveRound"
            @btnFunction="submit"
            class="round-save-button"
            :title="$t('save')"
            :loading="saveRoundLoading"
          />
        </div>
      </div>
    </div>
    <v-dialog max-width="400" width="400" v-model="dialog">
      <div class="dialog-container">
        <font-awesome-icon
          v-if="dialogVariant === 'success'"
          class="dialog-icon icon-success"
          icon="fa-solid fa-circle-check"
        />
        <font-awesome-icon
          v-if="dialogVariant === 'error'"
          class="dialog-icon icon-error"
          icon="fa-solid fa-circle-xmark"
        />
        <span class="dialog-title">{{ $t(dialogData.title) }}</span>
        <span class="dialog-subtitle"
          >{{
            $t(dialogData.subtitle, {
              roundId: dialogData.roundId,
              company: dialogData.company,
            })
          }}
        </span>
        <div class="dialog-button-container">
          <NewDxaButton
            outline
            @btnFunction="resetDialog"
            class="round-save-button"
            :title="$t('continue')"
            data-test="Round:NewRound:BtnCloseDialog"
          />
        </div>
      </div>
    </v-dialog>
    <v-dialog max-width="564" v-model="requiredDataDialog">
      <AlertModalRequiredData @closeDialog="requiredDataDialog = false" />
    </v-dialog>
    <!-- modal de alerta quando for publicar/despublicar uma rodada no pipeline -->
    <v-dialog max-width="564" v-model="publishRoundPipelineAlert">
      <div class="dialog-publish-round-pipeline-bg">
        <div class="dialog-publish-round-pipeline-content">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="dialog-publish-round-pipeline-content-alert-icon"
          />
          <h1 class="dialog-publish-round-pipeline-content-title">
            {{ publishRoundPipelineTitle }}
          </h1>
          <p class="dialog-publish-round-pipeline-content-text">
            {{ publishRoundPipelineText }}
          </p>
          <div class="dialog-publish-round-pipeline-content-buttons">
            <div class="dialog-publish-round-pipeline-content-button">
              <NewDxaButton
                @btnFunction="closeAlertPublishRoundPipeline"
                :outline="true"
                :title="$t('cancel')"
              />
            </div>
            <div class="dialog-publish-round-pipeline-content-button">
              <NewDxaButton
                @btnFunction="goPublishRoundPipeline"
                :title="publishRoundPipelineButtonText"
                :loading="publishRoundLoading"
              />
            </div>
          </div>
        </div>
        <font-awesome-icon
          @click="closeAlertPublishRoundPipeline"
          icon="fa-solid fa-xmark"
          class="dialog-publish-round-pipeline-icon-close-dialog"
        />
      </div>
    </v-dialog>
    <!-- modal de alerta quando for publicar/despublicar uma rodada no pipeline - cenário de erro -->
    <v-dialog max-width="564" v-model="publishRoundPipelineError">
      <div class="dialog-publish-round-pipeline-error-bg">
        <div class="dialog-publish-round-pipeline-error-content">
          <font-awesome-icon
            icon="fa-solid fa-circle-xmark"
            class="dialog-publish-round-pipeline-alert-icon-error"
          />
          <h1 class="dialog-publish-round-pipeline-error-content-title">
            {{ $t("publish_round_pipeline_alert_error_title") }}
          </h1>
          <p class="dialog-publish-round-pipeline-error-content-text">
            {{ $t("publish_round_pipeline_alert_error_sub_title") }}
          </p>
          <ul
            class="dialog-publish-round-pipeline-error-content-text dialog-publish-round-pipeline-error-content-list"
          >
            <li>{{ $t("investment_target") }}</li>
            <li>{{ $t("commitment_documents") }}</li>
            <li>{{ $t("closing_documents") }}</li>
            <li>
              {{ $t("publish_round_pipeline_alert_error_briefing_item") }}
            </li>
            <li>
              {{ $t("publish_round_pipeline_alert_error_user_permissions") }}
            </li>
          </ul>
          <div class="dialog-publish-round-pipeline-error-button">
            <NewDxaButton
              :outline="true"
              @btnFunction="closePublishRoundAlertError"
              :title="$t('close')"
            />
          </div>
        </div>
        <font-awesome-icon
          @click="closePublishRoundAlertError"
          icon="fa-solid fa-xmark"
          class="dialog-publish-round-pipeline-error-icon-close-dialog"
        />
      </div>
    </v-dialog>
    <v-dialog
      v-model="publishRoundSuccess"
      v-if="publishRoundSuccess"
      max-width="564"
    >
      <div class="dialog-publish-round-success-bg">
        <div class="dialog-publish-round-success-content">
          <font-awesome-icon
            @click="publishRoundPipelineError = false"
            icon="fa-solid fa-circle-check"
            class="dialog-publish-round-success-icon"
          />
          <h1 class="dialog-publish-round-title">
            {{ $t("publish_round_pipeline_alert_success_title") }}
          </h1>
          <p class="dialog-publish-round-text">
            {{
              $t("publish_round_pipeline_alert_success_text", {
                roundId: roundData.id,
                companyName: roundData.companyName,
              })
            }}
          </p>
          <div>
            <NewDxaButton
              @btnFunction="publishRoundSuccess = false"
              :outline="true"
              :title="$t('close')"
            />
          </div>
        </div>
        <font-awesome-icon
          @click="publishRoundSuccess = false"
          icon="fa-solid fa-xmark"
          class="dialog-publish-round-success-icon-close"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Tabs from "./components/Tabs";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { mapGetters, mapMutations } from "vuex";
import ApiService from "@/services/ApiService";
import AlertModalRequiredData from "./components/AlertModalRequiredData";
import { roundStatusEnums } from "./RoundStatusEnums";
import { convertLanguageToString } from "./helper/languageHelper";

export default {
  name: "NewRound",
  components: {
    NewDxaButton,
    Tabs,
    AlertModalRequiredData,
  },
  computed: {
    ...mapGetters({ roundData: "get_round_data" }),
  },
  data: () => ({
    apiService: new ApiService(),
    round: null,
    tab: 0,
    dialog: false,
    dialogVariant: null,
    dialogData: {},
    requiredDataDialog: false,
    publishRoundPipelineAlert: false,
    publishRoundPipelineError: false,
    publishRoundPipeline: false,
    publishRoundSuccess: false,
    publishRoundSwitchDisabled: true,
    publishRoundLoading: false,
    saveRoundLoading: false,
  }),
  async created() {
    this.setRoundData(null);

    //if(verificar o status da rodada pois se tiver status de publicada tem que ativar o v-model do swtich)
    await this.checkMethod();

    // verificando se a rodada existe se existir ativa o switch para publicar uma rodada
    if (this.roundData.id) {
      this.publishRoundSwitchDisabled = false;
    }

    // verificando se a rodada já está publicada para poder ativar o swtich
    if (this.roundData.roundStatus === roundStatusEnums.PUBLISHED) {
      this.publishRoundPipeline = true;
    }
  },
  methods: {
    async checkMethod() {
      if (this.$route.params.roundId) {
        const { roundId } = this.$route.params;
        await this.apiService
          .getRequest(`investmentround/${roundId}`)
          .then((result) => {
            this.setRoundData(result.content);
          })
          .catch(() => {
            this.$toast.error(this.$t("error_occoured"));
          });
      } else {
        // como é criação, vou limpar o vuex
        this.setRoundData(null);
      }
    },
    ...mapMutations({
      setRoundData: "SET_ROUND_DATA",
    }),
    submit() {
      // caso ocorra o clique no botão de salvar sem ter preenchido nenhum campo
      if (!this.roundData) this.requiredDataDialog = true;

      // verificando os dados do tipo required
      const { companyId, investmentVehicleInvestmentRounds, managerPartnerId } =
        this.roundData;

      if (
        companyId &&
        managerPartnerId && 
        ( this.roundData.preDeal || (
        investmentVehicleInvestmentRounds &&
        investmentVehicleInvestmentRounds.length))
      ) {
        if (this.roundData.id) {
          // update
          this.updateRound();
        } else {
          this.createNewRound();
          // create
        }
      } else {
        this.requiredDataDialog = true;
      }
    },
    resetDialog() {
      this.dialog = false;
      this.dialogVariant = null;
      this.dialogData = {};
    },
    putRoundIdInUrl(roundId) {
      window.history.pushState({}, null, roundId.toString());
    },
    createNewRound() {
      this.saveRoundLoading = true;
      this.convertCommitmentTermToString();

      // "PartnerConditionInvestmentRounds": [] precisa ser enviado como um array vazio, segundo gugu
      this.apiService
        .postRequest("investmentround", {
          ...this.roundData,
          PartnerConditionInvestmentRounds: [],
        })
        .then((result) => {
          this.dialog = true;
          this.dialogVariant = "success";
          this.dialogData = {
            title: "round_created_success_title",
            subtitle: "round_created_success_subtitle",
            roundId: result.content.Id,
            company: result.content.companyName,
          };
          let roundDataReturn = result.content;

          // Populando a array de veículos com os dados que temos no vuex, pois o retorno desse post não vem o closingType e vehicleNationality.
          // Após salvar, quebra o step de closing.
          // Como tempos esses dados no vuex, não precisa ter esse ajuste para o lado do back.
          roundDataReturn.investmentVehicleInvestmentRounds =
            this.roundData.investmentVehicleInvestmentRounds;

          this.setRoundData(roundDataReturn);
          this.publishRoundSwitchDisabled = false;
          this.saveRoundLoading = false;
          this.putRoundIdInUrl(result.content.id);
        })
        .catch((err) => {
          this.dialog = true;
          this.dialogVariant = "error";
          this.dialogData = {
            title: "round_created_error_title",
            subtitle: "round_created_error_subtitle",
          };
          this.saveRoundLoading = false;
        });
    },
    updateRound() {
      this.convertCommitmentTermToString();

      this.saveRoundLoading = true;
      this.apiService
        .putRequest("investmentround", this.roundData)
        .then((result) => {
          this.dialog = true;
          this.dialogVariant = "success";
          this.dialogData = {
            title: "round_created_success_title",
            subtitle: "round_created_success_subtitle",
          };
          this.publishRoundSwitchDisabled = false;
          this.saveRoundLoading = false;
        })
        .catch((err) => {
          this.dialog = true;
          this.dialogVariant = "error";
          this.dialogData = {
            title: "round_created_error_title",
            subtitle: "round_created_error_subtitle",
          };
          this.saveRoundLoading = false;
        });
    },
    publishRoundToPipeline(value) {
      this.publishRoundPipelineAlert = true;

      // verificando se esta publicando ou despublicando a rodada no pipeline para colocar o texto referente a cada ação
      if (value) {
        this.publishRoundPipelineTitle = this.$t(
          "publish_round_pipeline_alert_title"
        );
        this.publishRoundPipelineText = this.$t(
          "publish_round_pipeline_alert_text",
          {
            roundId: this.roundData.id,
            companyName: this.roundData.companyName,
          }
        );
        this.publishRoundPipelineButtonText = this.$t("publish");
      } else {
        this.publishRoundPipelineTitle = this.$t(
          "unpublish_round_pipeline_title"
        );
        this.publishRoundPipelineText = this.$t(
          "unpublish_round_pipeline_text",
          {
            roundId: this.roundData.id,
            companyName: this.roundData.companyName,
          }
        );
        this.publishRoundPipelineButtonText = this.$t("remove_from");
      }
    },
    closeAlertPublishRoundPipeline() {
      this.publishRoundPipelineAlert = false;
      this.publishRoundPipeline = !this.publishRoundPipeline;
    },
    closePublishRoundAlertError() {
      this.publishRoundPipelineError = false;
      this.publishRoundPipeline = !this.publishRoundPipeline;
    },
    async goPublishRoundPipeline() {
      this.publishRoundLoading = true;
      const publishRound =
        this.roundData.roundStatus === roundStatusEnums.AWAITING_PUBLISH;

      await this.apiService
        .putRequest(`investmentround/publish?id=${this.roundData.id}`)
        .then((result) => {
          if (publishRound) {
            this.publishRoundPipelineAlert = false;
            this.publishRoundSuccess = true;
          } else {
            this.publishRoundPipelineAlert = false;
            this.$toast.success(this.$t("saved"));
          }

          this.reloadRound();
        })
        .catch((err) => {
          if (publishRound) {
            this.$toast.error(this.$t("an_error_occurred"));
            this.publishRoundPipelineAlert = false;
            this.publishRoundPipelineError = true;
          } else {
            this.$toast.error(this.$t("an_error_occurred"));
            this.publishRoundPipelineAlert = false;
          }
        });

      this.publishRoundLoading = false;
    },
    async reloadRound() {
      await this.checkMethod();
    },
    convertCommitmentTermToString() {
      let { commitmentTermUrl, investmentProxyUrl } = this.roundData;

      if (typeof commitmentTermUrl === "object") {
        this.roundData.commitmentTermUrl = convertLanguageToString(
          this.roundData.commitmentTermUrl
        );
      }

      if (typeof investmentProxyUrl === "object") {
        this.roundData.investmentProxyUrl = convertLanguageToString(
          this.roundData.investmentProxyUrl
        );
      }
    },
  },
};
</script>

<style scoped>
.round-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.round-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.round-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}
p,
h1,
h2,
ul,
li {
  margin: 0px;
  padding: 0px;
}

#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
  height: unset;
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
}

.rounds-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.rounds-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.rounds-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.content {
  background: var(--white);
  border-radius: 12px;
  padding: 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.round-control {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
}

.round-switch-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #afafaf;
}

.round-save-button {
  width: 30%;
}

.round-save-btn-container {
  width: 30%;
}

.dialog-container {
  background-color: var(--white);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dialog-icon {
  width: 50px;
  height: 50px;
}

.icon-success {
  color: var(--primary);
}

.icon-error {
  color: #ff0000;
}

.dialog-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 12px;
}

.dialog-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.dialog-button-container {
  width: 30%;
  margin-top: 12px;
}

/* estilos do modal de publicação de uma rodada no pipeline */
.dialog-publish-round-pipeline-bg,
.dialog-publish-round-pipeline-error-bg,
.dialog-publish-round-success-bg {
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
  padding: 24px;
}

.dialog-publish-round-pipeline-content,
.dialog-publish-round-pipeline-error-content,
.dialog-publish-round-success-content {
  display: grid;
  place-items: center;
  margin-top: 56px;
}

.dialog-publish-round-pipeline-content-alert-icon {
  width: 44px;
  height: 38px;
  color: #ff9601;
  margin-bottom: 12px;
}

.dialog-publish-round-pipeline-content-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
}

.dialog-publish-round-pipeline-content-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 28px;
}

.dialog-publish-round-pipeline-content-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dialog-publish-round-pipeline-content-button {
  min-width: 192px;
}

.dialog-publish-round-pipeline-icon-close-dialog,
.dialog-publish-round-pipeline-error-icon-close-dialog,
.dialog-publish-round-success-icon-close {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #afafaf;
}

.dialog-publish-round-pipeline-error-content-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
  text-align: center;
}

.dialog-publish-round-pipeline-error-content-text,
.dialog-publish-round-pipeline-error-content-tex > li {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.dialog-publish-round-pipeline-error-content-list {
  margin-bottom: 37px;
  list-style: none;
  display: grid;
  gap: 4px;
}

.dialog-publish-round-pipeline-error-content-list > li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dialog-publish-round-pipeline-error-content-list > li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: var(--dark);
  border-radius: 50%;
}

.dialog-publish-round-pipeline-alert-icon-error {
  color: #ff0000;
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

.dialog-publish-round-success-content {
  margin-top: 40px;
}

.dialog-publish-round-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
  text-align: center;
}

.dialog-publish-round-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 38px;
}

.dialog-publish-round-success-icon {
  color: var(--primary);
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 769px) {
  #rounds-bg {
    min-height: 100vh !important;
  }
}
</style>
