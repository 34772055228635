<template>
  <div class="commitment-modal-bg">
    <div class="commitment-modal-header">
      <h1 class="commitment-modal-title">
        {{ $tc("specials_condition_partner", 2) }}
      </h1>
      <h2 class="commitment-modal-sub-title">
        {{ $t("briefing_informations") }}
      </h2>
    </div>
    <v-form
      class="commitment-modal-form"
      @submit.stop.prevent="checkForm()"
      ref="form"
    >
      <div>
        <span class="commitment-form-label">{{ $tc("partner", 1) }}</span>
        <span class="commitment-form-label-description">{{
          $t("select_partner_add_conditions")
        }}</span>
        <v-autocomplete
          data-test="Round:CommitmentPartnerModal:SelectPartner"
          :items="allPartners"
          :disabled="partnerOptionsDisabled"
          item-text="name"
          item-value="id"
          v-model="partnerB2bId"
          color="primary"
          solo
          dense
          :label="$t('select_label')"
          outlined
          :rules="[required]"
        >
        </v-autocomplete>
      </div>
      <div class="commitment-form-pt">
        <h2 class="commitment-form-title">
          {{ $t("term_title_language_pt") }}
        </h2>
        <div>
          <span class="commitment-form-label">{{
            $t("partner_term_commitment", { language: "pt" })
          }}</span>
          <span class="commitment-form-label-description">{{
            $t("round_closing_clicksign_input")
          }}</span>
          <v-text-field
            data-test="Round:CommitmentPartnerModal:PartnerCommitmentTerm"
            v-model="partnerCommitmentTermUrlPt"
            color="primary"
            dense
            class="commitment-form-input-text"
            outlined
            solo
            :rules="[required]"
          >
          </v-text-field>
        </div>
        <div>
          <span class="commitment-form-label">{{
            $t("partner_proxy", { language: "pt" })
          }}</span>
          <span class="commitment-form-label-description">{{
            $t("round_closing_clicksign_input")
          }}</span>
          <v-text-field
            data-test="Round:CommitmentPartnerModal:PartnerProxyUrl"
            class="commitment-form-input-text"
            v-model="partnerInvestmentProxyUrlPt"
            :rules="[required]"
            color="primary"
            dense
            outlined
            solo
          ></v-text-field>
        </div>
      </div>
      <div class="commitment-form-en">
        <h2 class="commitment-form-title">
          {{ $t("term_title_language_en") }}
        </h2>
        <div>
          <span class="commitment-form-label">{{
            $t("partner_term_commitment", { language: "en" })
          }}</span>
          <span class="commitment-form-label-description">{{
            $t("round_closing_clicksign_input")
          }}</span>
          <v-text-field
            data-test="Round:CommitmentPartnerModal:PartnerCommitmentTermEnglish"
            v-model="partnerCommitmentTermUrlEn"
            color="primary"
            dense
            class="commitment-form-input-text"
            outlined
            solo
            :rules="[required]"
          >
          </v-text-field>
        </div>
        <div>
          <span class="commitment-form-label">{{
            $t("partner_proxy", { language: "en" })
          }}</span>
          <span class="commitment-form-label-description">{{
            $t("round_closing_clicksign_input")
          }}</span>
          <v-text-field
            data-test="Round:CommitmentPartnerModal:PartnerProxyUrlEnglish"
            class="commitment-form-input-text"
            v-model="partnerInvestmentProxyUrlEn"
            :rules="[required]"
            color="primary"
            dense
            outlined
            solo
          ></v-text-field>
        </div>
      </div>
      <div class="commitment-forma-button">
        <NewDxaButton
          data-test="Round:CommitmentPartnerModal:ButtonSaveSpecialsPartnerConditions"
          :loading="loading"
          type="submit"
          :title="$t('add_title')"
        />
      </div>
    </v-form>
    <div class="commitment-button-close-modal" @click="closeDialog">
      <v-icon large color="#535353">mdi-close</v-icon>
    </div>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import ApiService from "@/services/ApiService.js";
import { convertLanguageToString } from "../helper/languageHelper";

export default {
  name: "CommitmentPartnerModal",
  components: {
    NewDxaButton,
  },
  data() {
    return {
      loading: false,
      apiService: new ApiService(),
      allPartners: [],
      partnerB2bId: null,
      partnerCommitmentTermUrl: null,
      partnerInvestmentProxyUrl: null,
      partnerOptionsDisabled: false,
      partnerCommitmentTermUrlEn: null,
      partnerInvestmentProxyUrlEn: null,
    };
  },
  props: {
    editDialog: Boolean,
    dataToEdit: Object,
  },
  async created() {
    await this.getAllPartners();
    if (this.editDialog) this.checkEdit();
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    checkForm() {
      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        let payload = {
          partnerB2bId: this.partnerB2bId,
          partnerCommitmentTermUrl: convertLanguageToString({
            pt: this.partnerCommitmentTermUrlPt,
            en: this.partnerCommitmentTermUrlEn,
          }),
          partnerInvestmentProxyUrl: convertLanguageToString({
            pt: this.partnerInvestmentProxyUrlPt,
            en: this.partnerInvestmentProxyUrlEn,
          }),
        };

        // emitindo o evento que leva os valores preenchidos no modal mais a prop se está editando ou não
        this.$emit("conditionPartner", payload, this.editDialog);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async getAllPartners() {
      this.loading = true;
      await this.apiService
        .getRequest("partnerb2b")
        .then((result) => {
          this.allPartners = result.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
      this.loading = false;
    },
    checkEdit() {
      const partnerInvestmentProxyUrl = JSON.parse(
        this.dataToEdit.partnerInvestmentProxyUrl
      );
      const partnerCommitmentTermUrl = JSON.parse(
        this.dataToEdit.partnerCommitmentTermUrl
      );

      // fazendo o controle de estado de acordo com as dados para edição
      this.partnerOptionsDisabled = true;
      this.partnerB2bId = this.dataToEdit.partnerB2bId;
      this.partnerCommitmentTermUrlPt = partnerCommitmentTermUrl.pt;
      this.partnerInvestmentProxyUrlPt = partnerInvestmentProxyUrl.pt;
      this.partnerCommitmentTermUrlEn = partnerCommitmentTermUrl.en;
      this.partnerInvestmentProxyUrlEn = partnerInvestmentProxyUrl.en;
    },
  },
};
</script>

<style scoped>
.commitment-modal-bg {
  background-color: var(--white);
  padding: 40px;
  border-radius: 12px;
  position: relative;
  overflow-y: scroll;
}

.commitment-modal-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.commitment-modal-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.commitment-modal-header {
  margin-bottom: 30px;
}

.commitment-modal-form {
  width: 50%;
}

.commitment-form-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
}

.commitment-form-label-description {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 4px;
}

.commitment-forma-button {
  max-width: 296px;
}

.commitment-button-close-modal {
  position: absolute;
  top: 32px;
  right: 64px;
  cursor: pointer;
  padding: 4px;
}

.commitment-form-input-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  color: var(--dark);
}

.commitment-form-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 30px;
}
</style>
