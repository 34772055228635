<template>
  <div class="briefing-and-permission-content">
    <div class="briefing-content">
      <h1 class="briefing-content-title">{{ $t("round_briefing_title") }}</h1>
      <div class="briefing-disclaimer-content">
        <span class="briefing-content-sub-title">
          {{ $t("round_briefing_sub_title") }}
        </span>
        <span class="briefing-and-permissions-disclaimer"
          >{{ $t("round_briefing_disclaimer") }}
        </span>
      </div>
      <div class="briefing-content-button">
        <NewDxaButton
          :disabled="!isValid"
          @btnFunction="openBriefing"
          :outline="true"
          :title="$t('round_briefing_title_button')"
          data-test="Round:BriefingAndPermission:BtnAddInfo"
        />
      </div>
      <div class="round-briefing-permission-section">
        <h1 class="briefing-content-title">{{ $t("permissions") }}</h1>
        <div class="briefing-disclaimer-content">
          <span class="briefing-content-sub-title">
            {{ $t("round_permission_label") }}
          </span>
          <span class="briefing-and-permissions-disclaimer"
            >{{ $t("round_permission_disclaimer") }}
          </span>
        </div>
        <span class="round-briefing-opportunity-label">
          {{ $t("round_permission_opportinity") }}
        </span>
        <v-radio-group
          row
          class="round-briefing-radio-group"
          v-model="roundData.typeOpportunity"
        >
          <v-radio
            :value="roundTypeOpportunity.AvailableOpportunity"
            data-test="Round:BriefingAndPermission:RadioAvailableOpportunity"
          >
            <template v-slot:label>
              <span class="round-briefing-radio-label">{{
                $t("opportunity_available")
              }}</span>
            </template>
          </v-radio>
          <v-radio
            :value="roundTypeOpportunity.ExclusiveOpportunity"
            data-test="Round:BriefingAndPermission:RadioExclusiveOpportunity"
          >
            <template v-slot:label>
              <span class="round-briefing-radio-label"
                >{{ $t("opportunity_exclusive") }}
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div class="round-permission-button-section">
        <div class="round-permission-button">
          <NewDxaButton
            :disabled="disabledButtonUsersPermissions"
            @btnFunction="openPermissions"
            :outline="true"
            :title="$t('round_user_permissions')"
            data-test="Round:BriefingAndPermission:BtnUserPermissions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { mapState, mapGetters } from "vuex";
import { roundTypeOpportunity } from "../RoundStatusEnums.js";

export default {
  name: "BriefingAndPermission",
  components: {
    NewDxaButton,
  },
  data() {
    return {
      opportunityType: 0,
      roundTypeOpportunity: roundTypeOpportunity,
      disabledButtonUsersPermissions: true,
    };
  },
  props: {
    isValid: Boolean,
  },
  computed: {
    ...mapState(["round"]),
    ...mapGetters({ roundData: "get_round_data" }),
  },
  watch: {
    "roundData.typeOpportunity"() {
      this.disabledButtonUsersPermissions =
        this.roundData.typeOpportunity !==
        roundTypeOpportunity.ExclusiveOpportunity;
    },
  },
  methods: {
    openBriefing() {
      this.$router.push(`new/briefing/${this.round.briefingId}`);
    },
    openPermissions() {
      this.$router.push(`permissions/${this.round.id}`);
    },
  },
};
</script>

<style scoped>
.briefing-disclaimer-content {
  display: flex;
  flex-direction: column;
}

.briefing-and-permissions-disclaimer {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #ff9601;
  margin-bottom: 24px;
}
.briefing-content {
  margin-bottom: 30px;
}

.briefing-and-permission-content {
  max-width: 50%;
  padding-left: 20px;
}

.briefing-content-button {
  max-width: 284px;
}

.briefing-content-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 20px;
}

.briefing-content-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-briefing-permission-section {
  margin-top: 28px;
}

.round-briefing-radio-group {
  display: flex;
  flex-direction: row;
}

.round-briefing-opportunity-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-briefing-radio-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-permission-button {
}

.round-permission-button-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
