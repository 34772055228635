import { render, staticRenderFns } from "./Closing.vue?vue&type=template&id=08b22610&scoped=true&"
import script from "./Closing.vue?vue&type=script&lang=js&"
export * from "./Closing.vue?vue&type=script&lang=js&"
import style0 from "./Closing.vue?vue&type=style&index=0&id=08b22610&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b22610",
  null
  
)

export default component.exports